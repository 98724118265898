@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Playfair+Display:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    background-color: #053161;
    font-family: 'Playfair Display', serif;
    color: #BED9DA;
}

.container {
    padding-left: 8%;
    min-height: 95vh;

    padding-right: 8%;
    box-sizing: border-box;
    overflow: hidden;
}

.navbar {
    display: flex;
    align-items: center;
}

.logo {
    width: 3.75rem;
    cursor: pointer;
    margin: 1.875rem 0 0 1.875rem;
}

.logo:hover {
    transform: scale(1.02, 1.02);
}

.menu {
    display: none;
    width: 1.8rem;
    cursor: pointer;
}

nav {
    flex: 1;
    text-align: right;
    padding-top: 1.25rem;
}

nav ul li {
    padding-right: 3.1rem;
    list-style: none;
    display: inline-block;
    margin-right: 3.125;
}

nav ul li a {
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 3rem;
}

nav ul li a:hover {
    color: #FCA59B;
}

.home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2.1rem 2.5rem 9rem 2.5rem;
}

.col-1 {
    position: relative;
    flex-basis: 40%;
    margin: 1.25rem 0 0 5rem;
}

.col-1 h1 span {
    color: #FCA59B;
    font-weight: 700;
}

.col-1 h3 {
    font-weight: 400;
    font-family: 'Open Sans', serif;
    margin: 0.625rem 0;
}

.col-1 p {
    font-weight: 400;
    font-family: 'Open Sans', serif;
    padding-top: 1.875rem;
}

.btn {
    color: #053161;
    font-family: 'Open Sans', serif;
    background-color: #FCA59B;
    border: none;
    padding: 0.625rem 2.5rem;
    border-radius: 0.094rem;
    margin: 2.5rem 0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.btn:hover {
    transform: scale(1.02, 1.02);
}

.col-2 {
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: 40%;
}

.img {
    margin-left: 0.625rem;
    max-width: 70%;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
    border-radius: 200%;
    margin-bottom: 2.5rem;
    min-height: 90px;
    max-height: auto;
}

.blogs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.blog-card {
    position: relative;
    border: 1px solid #FCA59B;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    text-decoration: none;
    width: 300px;
    height: 300px;
}

.blog-title {
    color: #FCA59B;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.blog-content {
    font-family: 'Open Sans', serif;
    font-size: 16px;
    line-height: 1.5;
}

.blog-content-wrapper {
    max-height: 43%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
}

.single-blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.blog-title-single {
    width: 900px;
    text-align: center;

    color: #FCA59B;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.blog-title-single b {
    font-family: 'Open Sans', serif;
    font-weight: 900;
}

.blog-content-single {
    font-family: 'Open Sans', serif;

    font-size: 18px;
    line-height: 2;
    text-align: center;
    width: 900px;
}


@media (max-width: 1920px) {
    .blog-card {
        width: 40%;
    }
    .blog-content {
        margin-right: 2rem;
    }

    .projects {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 1200px) {
    .blog-card {
        width: 40%;
    }

    .blog-content {
        margin-right: 0;
    }

    .projects {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 2rem;
    }
}

@media (max-width: 992px) {
    .blog-card {
        width: 50%;
    }

    .blog-content {
        margin-right: 0;
    }

    .projects {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .blog-card {
        width: 60%;
    }


    .blog-content {
        margin-right: 0;
    }

    .blog-title-single {
        width: 500px;
    }

    .blog-content-single {
        width: 500px;
    }


    .contact {
        display: flex;
        flex-wrap: wrap;
        justify-content: center ;
        margin: 0;
    }
}

@media (max-width: 576px) {
    .blog-card {
        width: 80%;
    }

    .blog-content {
        margin-right: 0;
    }

    .single-blog-content {
        width: 90%;
    }


    .blog-content-wrapper {
        max-height: 30%;

    }

    .blog-title-single {
        width: 370px;
    }

    .blog-content-single {
        width: 370px;
    }



}

@media (max-width: 400px) {
    .skills {
        margin-left: 0rem;
    }

    .blog-card {
        width: 80%;
    }

    .single-blog-content {
        width: 90%;
    }


    .blog-content-wrapper {
        max-height: 20%;

    }

    .blog-title-single {
        width: 300px;
    }

    .blog-content-single {
        width: 300px;
    }

    .projects {
        margin-left: 10rem;
    }

             

}


.skills-page {
    display: flex;
    justify-content: center;
    align-items: center;
}


.skills {
    margin: 2.5rem 2.5rem 6.5rem 1.5rem;
}

.tech-stack {
    display: inline-block;
    position: relative;
    margin: 1.25rem 0 0 5rem;
}

.tech-stack h3 {
    font-family: 'Open Sans', serif;
    font-weight: 600;
}

.tech-stack h3 b {
    font-family: 'Open Sans', serif;
    font-weight: 900;
    color: #FCA59B;
}

.tech-icons {
    display: flex;
    margin-bottom: 1.2rem;
}

.tech-icons div {
    padding-right: 1.5rem;
    padding-bottom: 2rem;
}

.design-stack {
    display: inline-block;
    position: relative;
    margin: 1.25rem 0 0 5rem;
}

.design-icons {
    display: flex;
}

.design-icons div {
    padding-right: 1.5rem;
}

.design-stack h3 {
    font-family: 'Open Sans', serif;
    font-weight: 600;
}

.design-stack h3 b {
    font-family: 'Open Sans', serif;
    font-weight: 900;
    color: #FCA59B;
}

.skill-img {
    margin: 1.25rem 0;
    margin-bottom: 0;
    width: 4.375rem;
}

.skill-names {
    text-align: center;
    margin-top: 0.25rem;
    font-weight: 400;
    font-size: 0.75rem;
    font-family: 'Open Sans', serif;
}

.proj {
    display: flex;
    width: 25rem;
    margin-bottom: 4rem;
}

.proj img {
    border-radius: .5rem;
    width: 10.09rem;
}

.proj-desc {
    padding-left: 1rem;
    width: 15rem;
}

.desc {
    margin-bottom: 0;
    margin-top: 0.3rem;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: 'Open Sans', serif;
}

.proj-desc h4 {
    font-family: 'Open Sans', serif;
}

.proj-desc span {
    font-weight: 400;
    font-size: 1.8;
    font-family: 'Open Sans', serif;
}

.proj-links {
    display: block;
    /* margin: 2.1rem 0 0 7rem; */
    margin: 2.1rem 8rem 0 0;
}

.proj-links img:hover {
    transform: scale(1.04, 1.04);
}

.proj-links img {
    cursor: pointer;
    width: 1.5rem;
    padding-right: 1rem;
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    margin: 1rem 0;
    text-align: center;
    font-family: 'Open Sans', serif;
    font-weight: 400;
    font-size: 0.75rem;
}

.work {
    display: inline-grid;
    grid-template-columns: auto;
    align-items: center;
    margin: 2rem 2.5rem 2.5rem 1.5rem;
    gap: 3rem;
}

a {
    text-decoration: none !important;
}

.content {
    margin: 0 0 0 5rem;
    border-left: 0.25rem solid #FCA59B;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.content h3 {
    font-weight: 600;
    font-size: 1.4rem;
    font-family: 'Open Sans', serif;
}

.content p {
    font-family: 'Open Sans', serif;
}

.org-img {
    float: right;
    width: 9rem;
}

.org-img:hover {
    transform: scale(1.01, 1.01);
}

.link-arrow {
    cursor: pointer;
    width: 0.5rem;
}

.link-arrow:hover {
    transform: scale(1.01, 1.01);
}

.role {
    color: #FCA59B;
    font-weight: 4rem;
    font-size: 0.9rem;
    margin: 0.3rem 0 1rem 0;
}

.role span {
    font-family: 'Open Sans', serif;
    font-size: 0.7rem;
}

.blogs {
    display: inline-block;
    align-items: center;
    margin: 3.75rem 2.5rem 2.5rem 6.5rem;
}

.contact {
    display: inline-block;
    align-items: center;
    margin: 3.75rem 2.5rem 2.5rem 6.5rem;
}

.contact h3 {
    font-family: 'Open Sans', serif;
    font-weight: 600;
    margin-bottom: 1rem;
}

.contact h4 {
    margin: 0.2rem 0 0 0;
    font-family: 'Open Sans', serif;
    font-weight: 400;
}

.contact h3 b {
    font-family: 'Open Sans', serif;
    font-weight: 900;
    color: #FCA59B;
}

.work-h3 {
    font-family: 'Open Sans', serif;
    font-weight: 600;
    margin: 1.3rem 0 0 5rem;
}

.h3 {
    font-family: 'Open Sans', serif;
    font-weight: 600;
}

.b {
    font-family: 'Open Sans', serif;
    font-weight: 900;
    color: #FCA59B;
}

.contact-imgs {
    display: flex;
    margin-top: 1rem;
}

.contact-imgs div {
    padding-right: 1.5rem;
    padding-bottom: 2rem;
}

.contact-img {
    margin: 1.25rem 0;
    margin-bottom: 0;
    width: 4.375rem;
}

.contact-img:hover {
    transform: scale(1.02, 1.02);
}

.contact-name {
    text-align: center;
    margin-top: 0.25rem;
    font-weight: 400;
    font-size: 0.75rem;
    font-family: 'Open Sans', serif;
}

.projects {
    display: grid;
    grid-template-columns: 30rem 30rem;
    grid-gap: 0.6rem;
    margin: 3.75rem 2.5rem 2.5rem 6.5rem;
}

.contact-illust img {
    margin-top: 0;
    margin-left: 37.75rem;
    width: 15rem;
}

.skills-illust img {
    margin-left: 25rem;
    width: 11rem;
}


/* Media queries */

@media screen and (max-width: 1024px) {
    .home {
        margin: 4.1rem 2.5rem 2.5rem 2.5rem;
    }

    .work {
        margin: 4.5rem 2.5rem 2.5rem 0;
    }

    .skills {
        margin: 0;
    }

    .skills-illust img {
        margin-left: 15rem;
        width: 11rem;
    }

    .contact {
        margin: 5.75rem 2.5rem 2.5rem 5rem;
    }

    .contact-illust img {
        margin-top: 1.5rem;
        margin-left: 28rem;
        width: 15rem;
    }
}

@media screen and (max-width: 768px) {
    .home {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 2.1rem 2.5rem 2.5rem 1.9rem;
    }

    .img {
        margin-left: auto;
        margin-right: auto;
        max-width: 45%;
    }

    .btn {
        margin: 2.5rem 0 0 8.78rem;
    }

    .col-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 40%;
    }

    .tech-stack {
        margin: 1.25rem 0 0 1rem;
    }

    .design-stack {
        margin: 1.25rem 0 0 1rem;
    }

    .tech-icons {
        flex-wrap: wrap;
    }

    .skills-illust img {
        margin-left: 10rem;
        width: 11rem;
    }

    .contact-illust img {
        margin: 10rem 0 0 10rem;
        width: 15rem;
    }

    .footer {
        margin-bottom: 0.1rem;
    }

    
    .projects {
        display: flex;
        flex-wrap: wrap;
        margin-left: 4rem;
    }
}

@media screen and (max-width: 600px) {
    .logo {
        width: 3.75rem;
        cursor: pointer;
        margin: 1rem 0 0 1.875rem;
        margin-left: 0.6rem;
    }

    .menuList {
        display: none;
        width: 100%;
        position: absolute;
        top: 6rem;
        right: 0;
        z-index: 20;
    }

    nav ul {
        border-bottom: 0.1rem solid #BED9DA;
    }

    nav ul li {
        display: block;
        font-size: 0.1rem;
    }

    .menu {
        margin-top: 1rem;
        margin-right: 1rem;
        display: block;
    }

    .img {
        max-width: 78%;
    }

    .work-h3 {
        margin: 1.3rem 0;
        margin-bottom: 0;
    }

    .home {
        margin-top: 3rem;
    }

    .col-1 {
        position: relative;
        flex-basis: 40%;
        margin: 1.25rem 0;
    }

    .btn {
        margin: 2.5rem 0 0 4.15rem;
    }

    .skills {
        margin-left: 1rem;
    }

    .tech-icons {
        flex-wrap: wrap;
    }

    .design-icons {
        flex-wrap: wrap;
    }

    .skills-illust {
        margin-top: 4rem;
    }

    .skills-illust img {
        margin-left: 7rem;
        width: 10rem;
    }

    .projects {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1rem;
    }

    .work {
        margin: 1.9rem 0 2.5rem 0;
    }

    .tech-stack {
        margin: 1.25rem 0 0 0;
    }

    .design-stack {
        margin: 1.25rem 0 0 0;
    }

    .content {
        margin: 1.5rem 0 0 0;
    }

    .contact {
        margin: 3rem 0;
    }

    .contact-illust img {
        margin-top: 0;
        margin-left: 0;
        width: 12rem;
    }

    .contact-imgs {
        display: grid;
        grid-template-columns: 100px 100px 100px;
    }

    .contact-img {
        margin-left: 0.22rem;
    }

    .proj {
        display: flex;
        flex-wrap: wrap;
        width: 25rem;
        height: 10rem;
        min-height: 0;
        overflow: visible;
        margin-bottom: 7rem;
    }

    .proj-desc {
        margin-top: 1rem;
        padding-left: 0;
        width: 17rem;
    }

    .proj img {
        border-radius: .5rem;
        width: 6.5rem;
        height: 6.5rem;
    }

    .proj-links {
        display: block;
        margin: 0;
    }

    .proj-links img {
        float: left;
        height: 2.4rem;
        cursor: pointer;
        width: 1.3rem;
        margin: 0;
    }

    .footer {
        position: relative;
    }
}